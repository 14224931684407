.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  padding: 7px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 20px;
}
 
.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .public-DraftStyleDefault-ul {
  margin: 0 0 20px 0;
  padding: 0px;
}

.RichEditor-editor .RichEditor-title {
  width: 800px;
  font-size: 20px;
  padding: 10px 0 30px 0;
  display: flex;
  justify-content: center;
}

.RichEditor-editor .RichEditor-header {
  font-size: 20px;
  padding: 0 0 0px 0;
}

.RichEditor-editor .RichEditor-paragraph {
  font-size: 16px;
  padding: 0px 0 0px 0;
}

.RichEditor-editor .RichEditor-small-font {
  font-size: 12px;
  padding: 0px 0 0px 0;
  /* line-height: 12px;  */
}

.RichEditor-editor .RichEditor-circle-list {
  list-style-type: circle;
  font-size: 16px;
  padding: 0px 0 0px 0;
}

.RichEditor-editor .RichEditor-watermark {
  font-size: 14px;
  padding: 0px 0 0px 0;
  color: gray;
  display: flex;
  justify-content: center;
}

.RichEditor-editor .RichEditor-leftAlign {
  display: flex;
  justify-content: start;
}

.RichEditor-editor .RichEditor-centerAlign {
  display: flex;
  justify-content: center;
}

.RichEditor-editor .RichEditor-rightAlign {
  display: flex;
  justify-content: end;
}

/* .RichEditor-editor .RichEditor-square-list {
  list-style-type: square;
  font-size: 16px;
  padding: 0px 0 0px 0;
} */

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
