


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;800&display=swap');


/* button:hover {
    opacity: 1 !important;
    text-decoration: none !important;
    color: #007bff !important;
    font-weight: 400 !important;
} */

*:focus {
  outline: none !important;
}

.blueLink:hover {
  text-decoration: underline !important;
}

*{
  font-family: "Montserrat",sans-serif;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
   print-color-adjust: exact;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

 @media print {
   .printEl {
       width: 12in;
       /* width: 12in; was what it was initially and may need to change it back */
       height: 11in;
   }

   .dontPrint {
   display: none !important;
 }
   .iFrame {
   display: none !important;
  }

  .topHeader p {
  color: #000 !important;
 }

}

.restaurantMenu {
    -webkit-box-shadow: 0 10px 6px -6px #777;
       -moz-box-shadow: 0 10px 6px -6px #777;
            box-shadow: 0 10px 6px -6px #777;
}

.logo {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

li {
  margin: 5px;
}


