@import url('https://fonts.googleapis.com/css2?family=Abel:wght@400&display=swap');


.ihcConsent * {
  font-family: "Abel", Arial, sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
}


/* .ihcConsent li {
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.ihcConsent p {
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
} */
